import React, {useState} from 'react';
import './App.css';
import {BrowserRouter as Router} from 'react-router-dom';
import Login from './Login';
import * as PropTypes from "prop-types";
import {Navbar} from "./components/Navbar";

Navbar.propTypes = {
    loggedIn: PropTypes.bool,
    onLogin: PropTypes.func
};

function App() {
    const [loggedIn, setLoggedIn] = useState(false);
    const [basicAuth, setBasicAuth] = useState("");
    const [roles, setRoles] = useState([]);

    const handleLogin = (basicAuth, roles) => {
        setBasicAuth(basicAuth)
        setRoles(roles)
        setLoggedIn(true);
    }

    const handleLogout = () => {
        setBasicAuth("")
        setRoles([])
        setLoggedIn(false);
    }

    return (
        <Router>
            <div className="App">
                <header>
                    <h1>Header</h1>
                    {loggedIn ? (
                        <button onClick={handleLogout}>Abmelden</button>
                    ) : (
                        <Login onLogin={handleLogin}/>
                    )}
                </header>
                <Navbar loggedIn={loggedIn} basicAuth={basicAuth} onLogin={handleLogin}/>
            </div>
        </Router>
    );
}

export default App;

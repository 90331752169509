import React, {useEffect, useState} from 'react';
import '../pages/Verwaltung.css';
import ApiService from "../services/ApiService";

function NeuePerson(props) {
    const geschlechtList = ["männlich", "weiblich"]
    const [loading, setLoading] = useState(false);
    const [displayText, setDisplayText] = useState('');
    const [vereinList, setVereinList] = useState([]);
    const [vorname, setVorname] = useState('');
    const [nachname, setNachname] = useState('');
    const [geburtsjahr, setGeburtsjahr] = useState('');
    const [geschlecht, setGeschlecht] = useState('');
    const [vereinId, setVereinId] = useState('');
    const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
            setLoading(true);
            ApiService.fetchVerein()
                .then(data => {
                    setVereinList(data);
                    setLoading(false);
                })
                .catch(error => console.error('Fehler beim Laden der Disziplinen:', error));
        }, [props]
    );

    if (loading) {
        return <p>Loading...</p>;
    }

    const handleVornameChange = (e) => {
        setDisplayText('')
        setVorname(e.target.value.trim());
    };
    const handleNachnameChange = (e) => {
        setDisplayText('')
        setNachname(e.target.value.trim());
    };
    const handleGeburtsjahrChange = (e) => {
        const value = e.target.value.trim();
        if (/^\d{4}$/.test(value)) {
            setDisplayText('')
            setGeburtsjahr(value);
        } else {
            setGeburtsjahr('')
            setDisplayText('Bitte geben Sie eine 4-stellige Zahl ein.');
        }
    };
    const handleVereinChange = (e) => {
        setVereinId(e.target.value);
    };
    const handleGeschlechtChange = (e) => {
        setGeschlecht(e.target.value);
    };

    const isButtonActiv = vorname !== '' && nachname !== '' && geburtsjahr !== '' && geschlecht !== '' && vereinId !== '';
    const handleSaveButtonClick = async () => {
        try {
            const response = await ApiService.createPerson(
                props,
                vorname,
                nachname,
                geburtsjahr,
                geschlecht,
                vereinId
            );
            if (response) {
                setDisplayText(`Daten erfolgreich gespeichert`);
                setHasError(false);
                setIsSaveSuccessful(true);
                props.onUpdateList();
            } else {
                setIsSaveSuccessful(false)
                setHasError(true);
                console.error('Fehler beim Speichern der Daten');
                setDisplayText('Fehler beim Speichern der Daten');
            }
        } catch (error) {
            setHasError(true);
            console.error('Fehler beim API-Aufruf', error);
            setDisplayText('Fehler beim API-Aufruf');
        }
    };

    return (
        <div>
            <p>neuen Schützen eingeben</p>
            <div>
                <label>
                    Vorname: <input type="text" onChange={handleVornameChange}/>
                </label>
            </div>
            <div>
                <label>
                    Nachname: <input type="text" onChange={handleNachnameChange}/>
                </label>
            </div>
            <div>
                <label>
                    Geburtsjahr: <input type="text" onChange={handleGeburtsjahrChange}/>
                </label>
            </div>
            <div>
                <label>
                    Geschlecht:
                </label>
                    <select value={geschlecht} onChange={handleGeschlechtChange}>
                        <option value="" disabled>
                            Geschlecht
                        </option>
                        {geschlechtList.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>

            <select value={vereinId}
                    onChange={handleVereinChange}>
                <option value="" disabled>
                    wähle Verein
                </option>
                {vereinList.map((option) => (
                    <option key={option.name} value={option.id}>
                        {option.name}
                    </option>
                ))}
            </select>
            <div>
            <button className={`saveButton ${isSaveSuccessful ? 'success' : ''} ${hasError ? 'error' : ''}`}
                    onClick={handleSaveButtonClick} disabled={!isButtonActiv}>Speichern
            </button>
            </div>
            <div className={`${isSaveSuccessful ? 'success' : ''} ${hasError ? 'error' : ''}`}>{displayText} </div>
        </div>

    )
        ;
}

export default NeuePerson;

import React, {useState} from 'react';
import './Wettkampf60.css';
import ApiService from "../services/ApiService";

function NeuesErgebnis({props, wettkampfId, disziplin, mannschaft, matchingPersons}) {
    const [displayText, setDisplayText] = useState('');
    const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [rows, setRows] = useState([
        {
            position: 1,
            personId: null,
            scheibenNr: null,
            s1: 0,
            s2: 0,
            s3: 0,
            sumStehend: 0,
            s4: 0,
            s5: 0,
            s6: 0,
            sumKniend: 0,
            gesamt: 0
        }
    ]);

    const handleAddRow = () => {
        setIsSaveSuccessful(false);
        setHasError(false);
        setRows([...rows, {
            position: rows.length + 1,
            personId: null,
            scheibenNr: null,
            s1: 0,
            s2: 0,
            s3: 0,
            sumStehend: 0,
            s4: 0,
            s5: 0,
            s6: 0,
            sumKniend: 0,
            gesamt: 0
        }]);
    };
    const handleRemoveRow = (index) => {
        setIsSaveSuccessful(false);
        setHasError(false);
        const updatedRows = [...rows];
        updatedRows.splice(index, 1);
        setRows(updatedRows);
    };

    const handlePersonChange = (e, index) => {
        setIsSaveSuccessful(false);
        setHasError(false);
        const updatedRows = [...rows];
        updatedRows[index].personId = e.target.value;
        setRows(updatedRows);
    };

    const handlePositionChange = (e, index) => {
        setIsSaveSuccessful(false);
        setHasError(false);
        const updatedRows = [...rows];
        updatedRows[index].position = e.target.value;
        setRows(updatedRows);
    };
    const handleScheibenChange = (rowIndex, field, value) => {
        setIsSaveSuccessful(false);
        setHasError(false);
        const updatedRows = [...rows];
        if (/^[\d\s\-–—−]+$/.test(value)) {
            updatedRows[rowIndex][field] = value.replace(/[^\d\s\-–—−_~]+/g, '-').trim();
            setDisplayText('');
        } else {
            updatedRows[rowIndex][field] = null;
            setDisplayText('Bitte geben Sie nur Zahlen oder - ein. Leerzeichen sind erlaubt');
        }
        setRows(updatedRows);
    };
    const handleSerieChange = (rowIndex, field, value) => {
        setIsSaveSuccessful(false);
        setHasError(false);
        setDisplayText('');
        const updatedRows = [...rows];
        if (/^\d{0,3}$/.test(value) && value <= 100) {
            updatedRows[rowIndex][field] = value;
            updatedRows[rowIndex].gesamt = calculateSum(updatedRows[rowIndex]);
            if (disziplin.requiredSeries === 6) {
                updatedRows[rowIndex].sumStehend = sumOfThree(updatedRows[rowIndex].s1, updatedRows[rowIndex].s2, updatedRows[rowIndex].s3);
                updatedRows[rowIndex].sumKniend = sumOfThree(updatedRows[rowIndex].s4, updatedRows[rowIndex].s5, updatedRows[rowIndex].s6);
            }
        } else {
            updatedRows[rowIndex][field] = 0;
            setDisplayText('Bitte geben Sie eine max. 3-stellige Zahl ein.');
        }
        setRows(updatedRows);
    };

    const calculateSum = (row) => {
        const filteredValues = [row.s1, row.s2, row.s3, row.s4, row.s5, row.s6].filter(value => value !== null && value !== '');
        if (filteredValues.length > 0) {
            return filteredValues.reduce((acc, curr) => acc + parseFloat(curr), 0);
        } else {
            return 0;
        }
    }

    const sumOfThree = (one, two, three) => {
        const filteredValues = [one, two, three].filter(value => value !== 0 && value !== null);
        if (filteredValues.length > 0) {
            return filteredValues.reduce((acc, curr) => acc + parseFloat(curr), 0);
        } else {
            return 0;
        }
    }

    const handleSaveButtonClick = async () => {
        try {
            if (rows.some(row => !row.personId)) {
                setHasError(true);
                setDisplayText('Schütze auswählen.');
                return;
            }
            const personIds = new Set();
            if (rows.some(row => {
                if (personIds.has(row.personId)) {
                    return true;
                } else {
                    personIds.add(row.personId);
                    return false;
                }
            })) {
                setHasError(true);
                setDisplayText('Jede Person darf nur 1 Ergebnis einreichen.');
                return;
            }
            if (rows.some(row => !row.scheibenNr)) {
                setHasError(true);
                setDisplayText('Scheibennummern angeben.');
                return;
            }

            const positions = new Set();
            if (rows.some(row => {
                if (positions.has(row.position)) {
                    return true; // Position kommt mehr als einmal vor
                } else {
                    positions.add(row.position);
                    return false;
                }
            })) {
                setHasError(true);
                setDisplayText('Positionen überprüfen.');
                return;
            }

            if (rows.length < disziplin.requiredStarters) {
                const confirmSave = window.confirm(
                    'Die Mannschaft ist nicht vollständig. Sind Sie sicher, dass Sie speichern wollen? ' +
                    'Ihre Mannschaft gilt dann als nicht angetreten und verliert den Wettkampf.'
                );
                if (!confirmSave) {
                    return; // Der Benutzer hat Abbrechen ausgewählt
                }
            }
            const ergebnisDto = {
                wettkampfId: Number(wettkampfId),
                mannschaftId: Number(mannschaft.id),
                ergebnisse: rows.map(row => ({
                    position: Number(row.position),
                    personId: Number(row.personId),
                    scheibenNr: row.scheibenNr,
                    s1: Number(row.s1),
                    s2: Number(row.s2),
                    s3: Number(row.s3),
                    sumStehend: Number(row.sumStehend),
                    s4: Number(row.s4),
                    s5: Number(row.s5),
                    s6: Number(row.s6),
                    sumKniend: Number(row.sumKniend),
                    gesamt: Number(row.gesamt)
                }))
            };
            const response = await ApiService.createErgebnisse(props, ergebnisDto);
            if (response) {
                setDisplayText(`Daten erfolgreich gespeichert`);
                setIsSaveSuccessful(true);
                setHasError(false);
            } else {
                setIsSaveSuccessful(false);
                setHasError(true);
                setDisplayText("allgemeiner Fehler");
            }
        } catch (error) {
            setIsSaveSuccessful(false)
            setHasError(true);
            setDisplayText(error.message);
        }
    };

    const renderRows = () => {
        return rows.map((row, index) => (
            <div key={index} style={{display: 'flex'}}>
                <button onClick={() => handleRemoveRow(index)} title="Zeile löschen">-</button>
                <label htmlFor="pos">Pos:</label>
                <select
                    id={`position-${index}`}
                    value={row.position || ''}
                    onChange={(e) => handlePositionChange(e, index)}
                >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value) => (
                        <option key={value} value={value}>
                            {value}
                        </option>
                    ))}
                </select>
                <label> Schütze: </label>
                <select id="personenDropdown"
                        value={row.personId || ''}
                        onChange={(e) => handlePersonChange(e, index)}>
                    <option value="" disabled>
                        --- wähle einen Schützen ---
                    </option>
                    {matchingPersons.map((person) => (
                        <option key={person.id} value={person.id}>
                            {person.nachname}, {person.vorname}
                        </option>
                    ))}
                </select>
                <div>
                    <label htmlFor="scheibennr">Scheiben-nr</label>
                    <input type="text" id={`scheibennr-${index}`}
                           onChange={(e) =>
                               handleScheibenChange(index, 'scheibenNr', e.target.value)} maxLength="15"
                           style={{width: '60px'}}/>
                </div>
                <div>
                    <label htmlFor='s1-${index}'>S1</label>
                    <input type="text" id={`s1-${index}`} onChange={(e) =>
                        handleSerieChange(index, 's1', e.target.value)} maxLength="3"
                           style={{width: '30px'}}/>
                </div>
                <div>
                    <label htmlFor="s2">S2</label>
                    <input type="text" id={`s2-${index}`} onChange={(e) =>
                        handleSerieChange(index, 's2', e.target.value)} maxLength="3"
                           style={{width: '30px'}}/>
                </div>
                <div>
                    <label htmlFor="s3">S3</label>
                    <input type="text" id={`s3-${index}`} onChange={(e) =>
                        handleSerieChange(index, 's3', e.target.value)} maxLength="3"
                           style={{width: '30px'}}/>
                </div>
                {disziplin.requiredSeries === 4 && (
                    <div>
                        <label htmlFor="s4">S4</label>
                        <input type="text" id={`s4-${index}`} onChange={(e) =>
                            handleSerieChange(index, 's4', e.target.value)} maxLength="3"
                               style={{width: '30px'}}/>
                    </div>
                )}
                {disziplin.requiredSeries === 6 && (
                    <div>
                        <div>
                                <span
                                    id={`gesamt-stehend-${index}`}>{row.sumStehend !== null ? row.sumStehend : ''}</span>
                        </div>
                        <div>
                            <label htmlFor='s4-${index}'>S4</label>
                            <input type="text" id={`s4-kniend-${index}`} onChange={(e) =>
                                handleSerieChange(index, 's4', e.target.value)} maxLength="3"
                                   style={{width: '30px'}}/>
                        </div>
                        <div>
                            <label htmlFor='s5-${index}'>S5</label>
                            <input type="text" id={`s5-kniend-${index}`} onChange={(e) =>
                                handleSerieChange(index, 's5', e.target.value)} maxLength="3"
                                   style={{width: '30px'}}/>
                        </div>
                        <div>
                            <label htmlFor='s6-${index}'>S6</label>
                            <input type="text" id={`s6-kniend-${index}`} onChange={(e) =>
                                handleSerieChange(index, 's6', e.target.value)} maxLength="3"
                                   style={{width: '30px'}}/>
                        </div>
                        <div>
                            <span id={`gesamt-kniend-${index}`}>{row.sumKniend !== null ? row.sumKniend : ''}</span>
                        </div>
                    </div>
                )}
                <div>
                    <label htmlFor="gesamt">Gesamt:</label>
                    <span id={`gesamt-${index}`}>{row.gesamt !== null ? row.gesamt : ''}</span>
                </div>
            </div>
        ))
    }
    return (
        <div>
            <label>Ergebnis eintragen für {mannschaft.name}</label>
            {renderRows()}
            <button onClick={handleAddRow}>weiteres Ergebnis eingeben</button>
            <div style={{marginTop: '10px'}}>
                <button className={`saveButton ${isSaveSuccessful ? 'success' : ''} ${hasError ? 'error' : ''}`}
                        onClick={handleSaveButtonClick}>speichern und freigeben
                </button>
                <div
                    className={`${isSaveSuccessful ? 'success' : ''} ${hasError ? 'error' : ''}`}>{displayText} </div>
            </div>

        </div>
    )
}

export default NeuesErgebnis;

import React, {useState} from 'react';
import './Verwaltung.css';
import NeuePerson from "../components/NeuePerson";
import NeueMannschaft from "../components/NeueMannschaft";
import NeuerVerein from "../components/NeuerVerein";
import Listen from "../Listen";

function Stammdaten(props) {
    const [updateList, setUpdateList] = useState(false);

    const handleUpdateListen = () => {
        setUpdateList((prevUpdate) => !prevUpdate);
    };
    return (
        <div className="element-content element-background">
            <div className="grid-container-stammdaten">
                <div className="left-panel-verwaltung">
                    <NeuerVerein basicAuth={props.basicAuth} onUpdateList={handleUpdateListen}/>
                </div>
                <div className="right-panel-stammdaten">
                    <Listen request="verein" updateList={updateList}/>
                </div>
                <div className="left-panel-verwaltung">
                    <NeueMannschaft basicAuth={props.basicAuth} onUpdateList={handleUpdateListen}/>
                </div>
                <div className="right-panel-stammdaten">
                    <Listen request="mannschaft" updateList={updateList}/>
                </div>
                <div className="left-panel-verwaltung">
                    <NeuePerson basicAuth={props.basicAuth} onUpdateList={handleUpdateListen}/>
                </div>
                <div className="right-panel-stammdaten">
                    <Listen request="person" updateList={updateList}/>
                </div>
            </div>
        </div>
    );
}

export default Stammdaten;

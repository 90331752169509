import React, {useEffect, useState} from 'react';
import './Mannschaftmeldung.css';
import NavbarDisziplin from "../components/NavbarDisziplin";
import ApiService from "../services/ApiService";

function Mannschaftsmeldung(props) {
    const [displayText, setDisplayText] = useState('');
    const [loading, setLoading] = useState(false);

    const [disziplinList, setDisziplinList] = useState([]);
    const [vereinList, setVereinList] = useState(null);
    const [mannschaftList, setMannschaftList] = useState(null);
    const [personList, setPersonList] = useState(null);

    const [selectedDisziplinId, setSelectedDisziplinId] = useState('');
    const [selectedVereinId, setSelectedVereinId] = useState('');
    const [selectedPersonId, setSelectedPersonId] = useState('');
    const [selectedMannschaft, setSelectedMannschaft] = useState('');

    const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);
    const [hasError, setHasError] = useState(false);

    const handleDisziplinClick = (selectedCode) => {
        setIsSaveSuccessful(false);
        setHasError(false);
        setSelectedDisziplinId(disziplinList.find(disziplin => disziplin.code === selectedCode).id);
        setSelectedVereinId('');
        setSelectedMannschaft('');
        setMannschaftList(null);
        setPersonList(null);

        ApiService.fetchVereinByDisziplin(selectedCode)
            .then(data => {
                setVereinList(data);
                setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Ligen:', error));
    };

    const handleVereinChange = (event) => {
        setIsSaveSuccessful(false);
        setHasError(false);
        setSelectedVereinId('');
        setSelectedMannschaft('');
        const selectedVereinId = event.target.value;
        const name = vereinList.find(verein => verein.id === parseInt(selectedVereinId)).name
        setSelectedVereinId(selectedVereinId);
        //    setLoading(true);

        ApiService.fetchMannschaftenByDisziplinAndVerein(selectedDisziplinId, selectedVereinId)
            .then(data => {
                setMannschaftList(data);
                //        setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Daten für die rechte Spalte:', error));

        ApiService.fetchPersonsByVerein(props.basicAuth, selectedVereinId)
            .then(data => {
                setPersonList(data);
                setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Ligen:', error));
    };

    const handleMannschaftChange = (event) => {
        setIsSaveSuccessful(false);
        setHasError(false);
        const selectedMannschaftId = event.target.value;
        setSelectedMannschaft(selectedMannschaftId);
    };

    const handlePersonChange = (event) => {
        setIsSaveSuccessful(false);
        setHasError(false);
        const selectedPersonId = event.target.value;
        setSelectedPersonId(selectedPersonId);
    };

    useEffect(() => {
        setLoading(true);
        ApiService.fetchDisziplin()
            .then(data => {
                setDisziplinList(data);
                setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Disziplinen:', error));
    }, []);
    if (loading) {
        return <p>Loading...</p>;
    }

    const handleSave = async () => {
        try {
            const response = await ApiService.createMannschaftsmeldung(
                props,
                selectedPersonId,
                selectedDisziplinId,
                selectedMannschaft);
            if (response) {
                setDisplayText(`Daten erfolgreich gespeichert`);
                setPersonList(prevList => {
                    return prevList.map(person => {
                        if (person.personId === parseInt(selectedPersonId)) {
                            return {
                                ...person,
                                disziplinId: selectedDisziplinId,
                                mannschaftId: selectedMannschaft
                            };
                        }
                        return person;
                    });
                });

                setHasError(false);
                setIsSaveSuccessful(true);
            } else {
                setIsSaveSuccessful(false)
                setHasError(true);
                console.error('Fehler beim Speichern der Daten');
                setDisplayText('Fehler beim Speichern der Daten');
            }
        } catch
            (error) {
            setHasError(true);
            console.error('Fehler beim API-Aufruf', error);
            setDisplayText('Fehler beim API-Aufruf');
        }
    };

    return (
        <div className="element-content element-background">
            <div className="grid-container-meldung">
                <div className="left-panel-meldung">
                    <NavbarDisziplin disziplinList={disziplinList} handleDisziplinClick={handleDisziplinClick}/>
                </div>
                <div className="mid-panel-meldung">
                    <div>
                        {vereinList && (
                            <select value={selectedVereinId} onChange={handleVereinChange}>
                                <option value="" disabled> wähle Verein</option>
                                {vereinList.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        )}
                    </div>
                    <div>
                        {mannschaftList && (
                            <select value={selectedMannschaft} onChange={handleMannschaftChange}>
                                <option value="" disabled> wähle Mannschaft</option>
                                {mannschaftList.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        id: {option.id} name:{option.name}
                                    </option>
                                ))}
                            </select>
                        )}
                    </div>
                </div>
                <div className="right-panel-meldung">
                    {selectedMannschaft && (
                        <div>
                            Melde einen Schütze für diese Mannschaft
                            <div className="selectPerson">
                                <select value={selectedPersonId} onChange={handlePersonChange}>
                                    <option value="" disabled> wähle Schützen</option>
                                    {personList.map((person) => (
                                        <option key={person.personId} value={person.personId}>
                                            {person.vorname} {person.nachname}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <button
                                className={`saveButton ${isSaveSuccessful ? 'success' : ''} ${hasError ? 'error' : ''}`}
                                onClick={handleSave}>Hinzufügen
                            </button>
                            <div className={`hint ${isSaveSuccessful ? 'success' : ''} ${hasError ? 'error' : ''}`}>
                                {displayText}
                            </div>
                        </div>
                    )}
                    {selectedMannschaft && (
                        <ul className="liste">
                            <p>Übersicht: </p>
                            Vorname ------- Nachname ------- Mannschaft
                            {personList.map((current, index) => (
                                <li key={current.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                                    <div className="grid-person">
                                        <label className="list-row">{current.vorname}</label>
                                        <label className="list-row">{current.nachname}</label>
                                        <label className="list-row">{current.mannschaftId}</label>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Mannschaftsmeldung;

import React, {useEffect, useState} from 'react';
import '../Body.css';
import NavbarLiga from "../components/NavbarLiga";
import Durchgang30 from "../components/Durchgang30";
import ApiService from "../services/ApiService";
import apiService from "../services/ApiService";
import LigaTabelle from "../LigaTabelle";

function Ab10ma() {
    const [loading, setLoading] = useState(false);
    const [ligaList, setLigaList] = useState([]);
    const [tableData, setTableData] = useState(null);

    const [selectedLiga, setSelectedLiga] = useState(null);
    const [wettkampfData, setWettkampfData] = useState(null);
    const [collectedErgebnisData, setCollectedErgebnisData] = useState([])

    const handleLigaClick = (selectedLiga) => {
        setSelectedLiga(selectedLiga);
        setWettkampfData(null);
        setTableData(null);
        ApiService.fetchTable(2024, selectedLiga.id)
            .then(data => {
                setTableData(data);
                setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Daten für die rechte Spalte:', error));

        ApiService.fetchPaarungen(2024, selectedLiga.id)
            .then(data => {
                const gruppierteDaten = {};

                data.forEach(wettkampf => {
                    const durchgang = wettkampf.durchgang;
                    if (!gruppierteDaten[durchgang]) {
                        gruppierteDaten[durchgang] = [];
                    }
                    gruppierteDaten[durchgang].push(wettkampf);
                    gruppierteDaten[durchgang].sort((a, b) => a.wettkampfId - b.wettkampfId);
                });
                setWettkampfData(gruppierteDaten);
                //        setLoading(false);

            })
            .catch(error => console.error('Fehler beim Laden der Daten für die rechte Spalte:', error));

        ApiService.fetchResults(2024, selectedLiga.id)
            .then(data => {
                setCollectedErgebnisData(data);
            })
            .catch(error => console.error('Fehler beim Laden der Ergebnisse:', error));
    };

    useEffect(() => {
        setLoading(true);
        apiService.fetchLigenByDisziplin("AB10ma")
            .then(data => {
                setLigaList(data);
                setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Ligen:', error));
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="element-content element-background">
            <div className="grid-show-liga">
                <div className="left-panel">
                    <NavbarLiga ligaList={ligaList} handleLigaClick={handleLigaClick}></NavbarLiga>
                </div>
                {wettkampfData && (
                    <div className="right-panel">
                        <Durchgang30 ligaId={selectedLiga.id} wettkampfData={wettkampfData}
                                     collectedErgebnisData={collectedErgebnisData}></Durchgang30>
                    </div>
                )}
                {tableData &&
                    <div className="table-panel">
                        <LigaTabelle tableData={tableData}></LigaTabelle>
                    </div>
                }
            </div>
        </div>
    );
}

export default Ab10ma;

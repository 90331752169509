import React, {useEffect, useState} from 'react';
import '../pages/Verwaltung.css';
import ApiService from "../services/ApiService";

function NeueMannschaft(props) {
    const [loading, setLoading] = useState(false);
    const [displayText, setDisplayText] = useState('');
    const [disziplinList, setDisziplinList] = useState([]);
    const [vereinList, setVereinList] = useState([]);
    const [disziplinId, setDisziplinId] = useState('');
    const [mannschaft, setMannschaft] = useState('');
    const [vereinId, setVereinId] = useState('');
    const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
            setLoading(true);
            ApiService.fetchDisziplin()
                .then(data => {
                    setDisziplinList(data);
                    setLoading(false);
                })
                .catch(error => console.error('Fehler beim Laden der Disziplinen:', error));

            ApiService.fetchVerein()
                .then(data => {
                    setVereinList(data);
                    setLoading(false);
                })
                .catch(error => console.error('Fehler beim Laden der Disziplinen:', error));
        }, [props]
    );

    if (loading) {
        return <p>Loading...</p>;
    }
    const handleDisziplinChange = (e) => {
        setDisziplinId(e.target.value);
    };
    const handleVereinChange = (e) => {
        setVereinId(e.target.value);
    };
    const handleMannschaftChange = (e) => {
        setMannschaft(e.target.value.trim());
    };

    const isButtonActiv = disziplinId !== '' && vereinId !== '' && mannschaft !== '';
    const handleSaveButtonClick = async () => {
        try {
            const response = await ApiService.createMannschaft(
                props,
                disziplinId,
                vereinId,
                mannschaft
            );
            if (response) {
                setDisplayText(`Daten erfolgreich gespeichert`);
                setHasError(false);
                setIsSaveSuccessful(true);
                props.onUpdateList();
            } else {
                setIsSaveSuccessful(false)
                setHasError(true);
                console.error('Fehler beim Speichern der Daten');
                setDisplayText('Fehler beim Speichern der Daten');
            }
        } catch (error) {
            setHasError(true);
            console.error('Fehler beim API-Aufruf', error);
            setDisplayText('Fehler beim API-Aufruf');
        }
    };

    return (
        <div>
            <p>neue Mannschaft eingeben</p>
            <div>

            </div>
            <div>
                <select value={disziplinId}
                        onChange={handleDisziplinChange}>
                    <option value="" disabled>
                        wähle Disziplin
                    </option>
                    {disziplinList.map((option) => (
                        <option key={option.code} value={option.id}>
                            {option.name}
                        </option>
                    ))}
                </select>
                <select value={vereinId}
                        onChange={handleVereinChange}>
                    <option value="" disabled>
                        wähle Verein
                    </option>
                    {vereinList.map((option) => (
                        <option key={option.name} value={option.id}>
                            {option.name}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <label>
                    Mannschaftsname: <input type="text" onChange={handleMannschaftChange}/>
                </label>
            </div>
            <button className={`saveButton ${isSaveSuccessful ? 'success' : ''} ${hasError ? 'error' : ''}`}
                    onClick={handleSaveButtonClick} disabled={!isButtonActiv}>Speichern
            </button>
            <div className={`hint ${isSaveSuccessful ? 'success' : ''} ${hasError ? 'error' : ''}`}>{displayText} </div>
        </div>

    );
}

export default NeueMannschaft;

import React, {useEffect, useState} from 'react';
import './Saisondaten.css';
import NavbarDisziplin from "./NavbarDisziplin";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import ApiService from "../services/ApiService";

function Saisondaten(props) {
    const [displayText, setDisplayText] = useState('');
    const [loading, setLoading] = useState(false);
    const [disziplinList, setDisziplinList] = useState([]);
    const [ligaList, setLigaList] = useState([]);
    const [mannschaftenList, setMannschaftenList] = useState([]);
    const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [draggedItem, setDraggedItem] = useState(null);

    const handleDisziplinClick = (selectedCode) => {
        ApiService.fetchLigenByDisziplin(selectedCode)
            .then(data => {
                setLigaList(data);
                setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Ligen:', error));

        ApiService.fetchMannschaftenByDisziplin(selectedCode)
            .then(data => {
                setMannschaftenList(data);
                setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Liste:', error));
    };

    useEffect(() => {
        setLoading(true);
        ApiService.fetchDisziplin()
            .then(data => {
                setDisziplinList(data);
                setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Disziplinen:', error));
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    const handleDragStart = (e, item) => {
        setDraggedItem(item);
        e.dataTransfer.setData('text/plain', JSON.stringify(item));
    };

    const handleDragOver = (e, overItem) => {
        e.preventDefault();
    };

    const handleDragEnd = () => {
        setDraggedItem(null);
    };

    const handleDrop = (e, targetLiga) => {
        e.preventDefault();
        const droppedItem = JSON.parse(e.dataTransfer.getData('text/plain'));
        if (droppedItem.liga === targetLiga) {
            const overItem = draggedItem;
            const tempPosition = overItem.position;
            overItem.position = draggedItem.position;
            draggedItem.position = tempPosition;

            setMannschaftenList((prevMannschaftenList) => {
                    const sameLigaTeams = prevMannschaftenList.filter(
                        (mannschaft) => mannschaft.liga !== null && mannschaft.liga.id === targetLiga.id
                    ).sort((a, b) => a.position - b.position);

                    return prevMannschaftenList.map((mannschaft) =>
                        mannschaft.liga === targetLiga
                            ? sameLigaTeams.find((team) => team.id === mannschaft.id)
                            : mannschaft
                    );
                }
            );
        } else {
            // Prüfe, ob die Mannschaft in der Liste der Ziel-Liga bereits vorhanden ist
            const isAlreadyInTargetLiga = mannschaftenList.some(mannschaft => mannschaft.id === droppedItem.id && mannschaft.liga === targetLiga);

            if (!isAlreadyInTargetLiga) {
                // Setze die Position basierend auf dem Index, an dem die Mannschaft in der Liste gedropt wird
                const currentMaCount = mannschaftenList
                    .filter((mannschaft) => mannschaft.liga !== null && mannschaft.liga.id === targetLiga.id)
                    .length;
                setMannschaftenList((prevMannschaftenList) =>
                    prevMannschaftenList.map((mannschaft) =>
                        mannschaft.id === droppedItem.id ? {
                            ...mannschaft,
                            liga: targetLiga,
                            position: currentMaCount + 1
                        } : mannschaft
                    )
                );
            };
        }
    }

    const handleSave = async () => {
        try {
            const response = await ApiService.createSaisondaten(props, mannschaftenList);
            if (response) {
                setDisplayText(`Daten erfolgreich gespeichert`);
                setHasError(false);
                setIsSaveSuccessful(true);
            } else {
                setIsSaveSuccessful(false)
                setHasError(true);
                console.error('Fehler beim Speichern der Daten');
                setDisplayText('Fehler beim Speichern der Daten');
            }
        } catch
            (error) {
            setHasError(true);
            console.error('Fehler beim API-Aufruf', error);
            setDisplayText('Fehler beim API-Aufruf');
        }
    };

    return (
        <div className="element-content element-background">
            <div className="grid-verwaltung-saison">
                <div className="left-panel-saison">
                    <NavbarDisziplin disziplinList={disziplinList} handleDisziplinClick={handleDisziplinClick}/>
                </div>
                <DndProvider backend={HTML5Backend}>
                    <div className="right-panel-saison">
                        {ligaList.map((liga) => (
                            <div
                                key={liga.id}
                                className="drop-list"
                                onDrop={(e) => handleDrop(e, liga)}
                                onDragOver={(e) => e.preventDefault()}
                            >
                                <div key={liga.name}>
                                    <strong>{liga.name}</strong>
                                    {mannschaftenList
                                        .filter((mannschaft) => mannschaft.liga !== null)
                                        .filter((mannschaft) => mannschaft.liga.id === liga.id)
                                        .sort((a, b) => a.position - b.position)
                                        .map((item, index) => (
                                            <div key={item.id} className="drag-item"
                                                 draggable
                                                 onDragStart={(e) => handleDragStart(e, item)}
                                                 onDragOver={(e) => handleDragOver(e, item)}
                                                 onDragEnd={handleDragEnd}>
                                                {`${index + 1}. ${item.name}`}
                                            </div>
                                        ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="table-panel-saison">
                        {mannschaftenList.filter((mannschaft) => mannschaft.liga === null)
                            .map((item) => (
                                <div
                                    className="drag-item"
                                    key={item.id}
                                    draggable
                                    onDragStart={(e) => handleDragStart(e, item)}
                                    onDragOver={(e) => handleDragOver(e, item)}
                                    onDragEnd={handleDragEnd}
                                >
                                    {item.name}
                                </div>
                            ))}
                        <button className={`saveButton ${isSaveSuccessful ? 'success' : ''} ${hasError ? 'error' : ''}`}
                                onClick={handleSave}>Speichern
                        </button>
                        <div
                            className={`hint ${isSaveSuccessful ? 'success' : ''} ${hasError ? 'error' : ''}`}>{displayText} </div>
                    </div>
                </DndProvider>
            </div>
        </div>
    );
}

export default Saisondaten;

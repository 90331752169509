import React from 'react';
import './Wettkampf60.css';

function Durchgang40({ligaId, wettkampfData, collectedErgebnisData}) {
    return (
        <div>
            {wettkampfData && (
                <div>
                    {Object.keys(wettkampfData).map((durchgang, index) => (
                            <div key={index}>
                                <h3>Durchgang {durchgang}</h3>
                                {wettkampfData[durchgang].map(wettkampf => {
                                    const resultsList = collectedErgebnisData.filter(result =>
                                        result.durchgang.toString() === durchgang &&
                                        result.wettkampfId === wettkampf.wettkampfId).sort((a, b) => a.position - b.position);
                                    return (
                                        <div key={wettkampf.id}>
                                            <div className="grid-wettkampf">
                                                <div className="mannschaft-heim">
                                                    <div>{wettkampf.mannschaftHeimName}</div>
                                                </div>
                                                <div className="mannschaft-heim-punkte">
                                                    <div>{wettkampf.mannschaftHeimEinzelpunkte}</div>
                                                </div>
                                                <div className="vs">
                                                    <div>:</div>
                                                </div>
                                                <div className="mannschaft-fern-punkte">
                                                    <div>{wettkampf.mannschaftFernEinzelpunkte}</div>
                                                </div>
                                                <div className="mannschaft-fern">
                                                    <div>{wettkampf.mannschaftFernName}</div>
                                                </div>
                                            </div>
                                            {resultsList && (
                                                <div>
                                                    {resultsList.map((result, index) => (
                                                        <div className="grid-ergebnis-60">
                                                            <div className="scheibe-heim-60">({result.scheibenNrHeim})
                                                            </div>
                                                            <div
                                                                className="name-heim-60"> {result.personNachnameHeim}, {result.personVornameHeim}</div>
                                                            <div className="s1-heim-60">{result.s1Heim}</div>
                                                            <div className="s2-heim-60">{result.s2Heim}</div>
                                                            <div className="s3-heim-60">{result.s3Heim}</div>
                                                            <div
                                                                className="gesamt-stehend-heim-60">{result.gesamtStehendHeim}</div>
                                                            <div
                                                                className="punkt-stehend-heim-60">{result.einzelpunktStehendHeim}</div>
                                                            <div className="s4-heim-60">{result.s4Heim}</div>
                                                            <div className="s5-heim-60">{result.s5Heim}</div>
                                                            <div className="s6-heim-60">{result.s6Heim}</div>
                                                            <div
                                                                className="gesamt-kniend-heim-60">{result.gesamtKniendHeim}</div>
                                                            <div
                                                                className="punkt-kniend-heim-60">{result.einzelpunktKniendHeim}</div>
                                                            <div className="gesamt-heim-60">{result.gesamtHeim}</div>
                                                            <div
                                                                className="punkt-heim-60">{result.einzelpunktHeim}</div>
                                                            <div className="vs-stehend-ergebnis-60" key={index}>:</div>
                                                            <div className="vs-kniend-ergebnis-60" key={index}>:</div>
                                                            <div className="vs-ergebnis-60" key={index}>:</div>
                                                            <div
                                                                className="punkt-stehend-fern-60">{result.einzelpunktStehendFern}</div>
                                                            <div
                                                                className="punkt-kniend-fern-60">{result.einzelpunktKniendFern}</div>
                                                            <div
                                                                className="punkt-fern-60">{result.einzelpunktFern}</div>
                                                            <div className="gesamt-fern-60">{result.gesamtFern}</div>
                                                            <div className="s1-fern-60">{result.s1Fern}</div>
                                                            <div className="s2-fern-60">{result.s2Fern}</div>
                                                            <div className="s3-fern-60">{result.s3Fern}</div>
                                                            <div
                                                                className="gesamt-stehend-fern-60">{result.gesamtStehendFern}</div>
                                                            <div className="s4-fern-60">{result.s4Fern}</div>
                                                            <div className="s5-fern-60">{result.s5Fern}</div>
                                                            <div className="s6-fern-60">{result.s6Fern}</div>
                                                            <div
                                                                className="gesamt-kniend-fern-60">{result.gesamtKniendFern}</div>
                                                            <div
                                                                className="name-fern-60"> {result.personNachnameFern}, {result.personVornameFern}</div>
                                                            <div className="scheibe-fern-60">({result.scheibenNrFern})
                                                            </div>
                                                        </div>
                                                    ))
                                                    }
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    )
                    }
                </div>
            )
            }
        </div>
    )
}

export default Durchgang40;

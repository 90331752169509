import React, {useState} from 'react';
import './App.css';
import ApiService from "./services/ApiService";

function Login({onLogin}) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = () => {
        let basicAuth = btoa(username + ":" + password)
        ApiService.fetchLogin(basicAuth)
            .then(roles => {
                onLogin(basicAuth, roles)
            })
    }

    return (
        <div className="login-form">
            <h2>Login</h2>
            <input
                type="text"
                placeholder="Benutzername"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
            />
            <input
                type="password"
                placeholder="Passwort"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <button onClick={handleLogin}>Anmelden</button>
        </div>
    );
}

export default Login;

import React from 'react';

function NavbarLiga({onLogin, handleLigaClick, ligaList}) {

    return (
        <nav className="navbarliga">
            {ligaList.map((liga) => (
                <button key={liga.id} onClick={() => handleLigaClick(liga)}>
                    {liga.name}
                </button>

            ))}

            {/* ... Weitere Teile der Navbar, wie Logout-Button, etc. ... */}
        </nav>
    );
}

export default NavbarLiga;

import React, {useEffect, useState} from 'react';
import './Ergebnisse.css';
import NavbarDisziplin from "./NavbarDisziplin";
import NavbarLiga from "./NavbarLiga";
import ConfigDurchgang from "./ConfigDurchgang";
import NeuesErgebnis from "./NeuesErgebnis";
import ApiService from "../services/ApiService";

function Ergebnisse(props) {
    const [loading, setLoading] = useState(false);

    const [disziplinList, setDisziplinList] = useState([]);
    const [ligaList, setLigaList] = useState([]);
    const [daten, setDaten] = useState(null);
    const [selectedWettkampfId, setSelectedWettkampfId] = useState(null);

    const [disziplinInfos, setDisziplinInfos] = useState({});
    const [mannschaftInfos, setMannschaftInfos] = useState({});
    const [matchingPersons, setMatchingPersons] = useState(null);

    const handleDisziplinClick = (selectedCode) => {
        const selectedDisziplin = disziplinList.find(dis => dis.code === selectedCode)
        setDisziplinInfos({
            requiredStarters: selectedDisziplin.requiredStarters,
            requiredSeries: selectedDisziplin.requiredSeries
        });
        setMatchingPersons(null);
        setDaten(null);
        ApiService.fetchLigenByDisziplin(selectedCode)
            .then(data => {
                setLigaList(data);
                setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Ligen:', error));
    };

    const handleLigaClick = (selectedLiga) => {
        setMatchingPersons(null);
        setDaten(null);
        //    setLoading(true);
         try {
            ApiService.fetchPaarungen(2024, selectedLiga.id)
                .then(data => {
                    setDaten(data);
                });
        } catch (error) {
            console.error('Fehler beim Laden der Daten für die rechte Spalte:', error);
        }
    };

    const handleMannschaftClick = (wettkampfId, selectedMannschaftId, selectedMannschaftName) => {
        setMannschaftInfos({id: selectedMannschaftId, name: selectedMannschaftName});
        setSelectedWettkampfId(wettkampfId);
        setMatchingPersons(null);

       ApiService.fetchPersonsByMannschaft(props, selectedMannschaftId)
            .then(data => {
                setMatchingPersons(data);
                setLoading(false);
            })
            .catch(error => console.error('Fehler beim Laden der Ligen:', error));

    }
    useEffect(() => {
        setLoading(true);
        ApiService.fetchDisziplin()
                .then(data => {
                    setDisziplinList(data);
                    setLoading(false);
                })
                .catch(error => console.error('Fehler beim Laden der Disziplinen:', error));
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="element-content element-background">
            <div className="grid-container-ergebnisse">
                <div className="left-panel-ergebnisse">
                    <NavbarDisziplin disziplinList={disziplinList} handleDisziplinClick={handleDisziplinClick}/>
                </div>
                <div className="left-panel-ergebnisse">
                    <NavbarLiga ligaList={ligaList} handleLigaClick={handleLigaClick}></NavbarLiga>
                </div>
                <div className="right-panel-ergebnisse">
                    {daten && (
                        <div>
                            <ConfigDurchgang wettkampfData={daten}
                                             handleMannschaftClick={handleMannschaftClick}></ConfigDurchgang>
                        </div>
                    )}
                </div>
                {matchingPersons && (
                    <div className="mid-panel-ergebnisse">
                        <NeuesErgebnis props={props}
                                       wettkampfId={selectedWettkampfId}
                                       disziplin={disziplinInfos}
                                       mannschaft={mannschaftInfos}
                                       matchingPersons={matchingPersons}></NeuesErgebnis>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Ergebnisse;

import React, {useEffect, useState} from 'react';
import './Liste.css';
import ApiService from "./services/ApiService";

function Setzliste({mannschaft, setzliste}) {

    console.log("Setzliste: " + JSON.stringify(setzliste));

    return (
        <div>
            <table className="liste-tabelle">
                <thead>
                <tr>
                    <th>Pos</th>
                    <th>Nachname</th>
                    <th>Vorname</th>
                    <th>DG 1</th>
                    <th>DG 2</th>
                    <th>DG 3</th>
                    <th>DG 4</th>
                    <th>DG 5</th>
                    <th>DG 6</th>
                    <th>Durchschnitt</th>
                </tr>
                </thead>
                <tbody>
                {setzliste.map((current, index) => (
                    <tr key={current.person.id} className={index % 2 === 0 ? 'even' : 'odd'}>
                        <td>{index + 1}</td>
                        <td>{current.person.nachname}</td>
                        <td>{current.person.vorname}</td>
                        <td>{current.wks[1]}</td>
                        <td>{current.wks[2]}</td>
                        <td>{current.wks[3]}</td>
                        <td>{current.wks[4]}</td>
                        <td>{current.wks[5]}</td>
                        <td>{current.wks[6]}</td>
                        <td>{current.avg}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
)
}

export default Setzliste;

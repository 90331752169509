import React, {useState} from 'react';
import '../pages/Verwaltung.css';
import ApiService from "../services/ApiService";

function NeuerVerein(props) {
    const verbandList =
        [
            "BSV",
            "BSSB",
            "SVBB",
            "BSB",
            "HH",
            "HSV",
            "LSV-MV",
            "NSSV",
            "NDSB",
            "NWDSB",
            "OSB",
            "PSSB",
            "RSB",
            "SVS",
            "SV-ST",
            "SSB",
            "SBSV",
            "TSB",
            "WSB",
            "WSV"]
    const [verein, setVerein] = useState('');
    const [verband, setVerband] = useState('');
    const [displayText, setDisplayText] = useState('');
    const [isSaveSuccessful, setIsSaveSuccessful] = useState(false);
    const [hasError, setHasError] = useState(false);

    const handleVereinChange = (e) => {
        setVerein(e.target.value.trim());
    };
    const handleVerbandChange = (e) => {
        setVerband(e.target.value);
    };

    const isButtonActiv = verein !== '' && verband !== '';

    const handleSaveButtonClick = async () => {
        try {
            const response = await ApiService.createVerein(
                props,
                verein,
                verband
            )
            if (response) {
                setDisplayText(`Daten erfolgreich gespeichert`);
                setHasError(false);
                setIsSaveSuccessful(true);
                props.onUpdateList();
            }
            if (!response) {
                setIsSaveSuccessful(false)
                setHasError(true);
                setDisplayText('Fehler beim Speichern der Daten');
            }
        } catch (error) {
            setIsSaveSuccessful(false)
            setHasError(true);
            console.error('Fehler beim API-Aufruf', error);
            setDisplayText('Fehler beim API-Aufruf');
        }
    };

    return (
        <div>
            <p>neuen Verein eingeben</p>
            <div>
                <label>
                    Verein
                </label>
                <input type="text" onChange={handleVereinChange}/>
            </div>
            <div>
                <label>
                    Verband
                </label>
                <select value={verband} onChange={handleVerbandChange}>
                    <option value="" disabled> wähle Verband</option>
                    {verbandList.map((option) => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
            </div>
            <button className={`saveButton ${isSaveSuccessful ? 'success' : ''} ${hasError ? 'error' : ''}`}
                    onClick={handleSaveButtonClick} disabled={!isButtonActiv}>Speichern
            </button>
            <div className={`hint ${isSaveSuccessful ? 'success' : ''} ${hasError ? 'error' : ''}`}>{displayText} </div>
        </div>

    );
}

export default NeuerVerein;

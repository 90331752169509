import React from 'react';

function NavbarDisziplin({onLogin, handleDisziplinClick, disziplinList}) {
    return (
        <nav className="navbardisziplin">
            {disziplinList.map((disziplin) => (
                <button key={disziplin.code} onClick={() => handleDisziplinClick(disziplin.code)}>
                    {disziplin.name}
                </button>
            ))}
        </nav>
    );
}

export default NavbarDisziplin;

import React, {} from 'react';

function Verwaltung(props) {

    return (
        <div className="element-content element-background">
            <div>
                <div></div>
                <div>
                    mal gucken was Toplevel hier in der Verwaltung für Informationen reinkommen...
                    die bekannten Seiten sind umgezogen in die Untermenus
                </div>
            </div>
        </div>
    );
}

export default Verwaltung;

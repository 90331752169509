import React from 'react';
import './ConfigWettkampf.css';

function ConfigDurchgang({wettkampfData, handleMannschaftClick}) {

    function groupAndSort(daten) {
        const gruppierteDaten = {};
        daten.forEach(wettkampf => {
            const durchgang = wettkampf.durchgang;
            if (!gruppierteDaten[durchgang]) {
                gruppierteDaten[durchgang] = [];
            }
            gruppierteDaten[durchgang].push(wettkampf);
            gruppierteDaten[durchgang].sort((a, b) => a.wettkampfId - b.wettkampfId);
        });
        return gruppierteDaten;
    }

    const gruppierteWettkämpfe = groupAndSort(wettkampfData);

    return (
        <div>
            {gruppierteWettkämpfe && (
                <div key="ligaWettkampf">
                    {Object.keys(gruppierteWettkämpfe).map((durchgang, index) => (
                        <div key={index}>
                            <div style={{marginBottom: '5px', alignItems: 'center'}}>Durchgang {durchgang}</div>
                            {gruppierteWettkämpfe[durchgang].map(wettkampf => (
                                <div className="grid-wettkampf-config" key={wettkampf.wettkampfId}>
                                    {wettkampf.freigabeHeim === false ? (
                                        <a href="#" className="heim-config"
                                           onClick={() => handleMannschaftClick(wettkampf.wettkampfId, wettkampf.mannschaftHeimId, wettkampf.mannschaftHeimName)}>
                                            {wettkampf.mannschaftHeimName}</a>
                                    ) : (
                                        <span className="heim-config">
                                                {wettkampf.mannschaftHeimName}</span>
                                    )}
                                    <div className="vs-config"> :</div>
                                    {wettkampf.freigabeFern === false ? (
                                        <a href="#" className="fern-config"
                                           onClick={() => handleMannschaftClick(wettkampf.wettkampfId, wettkampf.mannschaftFernId, wettkampf.mannschaftFernName)}>
                                            {wettkampf.mannschaftFernName} </a>
                                    ) : (
                                        <span className="fern-config">
                                                {wettkampf.mannschaftFernName}</span>
                                    )}
                                </div>
                            ))}
                        </div>
                    ))}
                </div>
            )
            }
        </div>
    )
}

export default ConfigDurchgang;
